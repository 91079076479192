import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)
V2_FACTORY_ADDRESSES[SupportedChainId.LUX] = "0xD173926A10A0C4eCd3A51B1422270b65Df0551c1";
V2_FACTORY_ADDRESSES[SupportedChainId.LUX_TESTNET] = "0xD173926A10A0C4eCd3A51B1422270b65Df0551c1";
V2_FACTORY_ADDRESSES[SupportedChainId.ZOO] = "0xD173926A10A0C4eCd3A51B1422270b65Df0551c1";
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
V2_ROUTER_ADDRESS[SupportedChainId.LUX] = "0xAe2cf1E403aAFE6C05A5b8Ef63EB19ba591d8511";
V2_ROUTER_ADDRESS[SupportedChainId.LUX_TESTNET] = "0xAe2cf1E403aAFE6C05A5b8Ef63EB19ba591d8511";
V2_ROUTER_ADDRESS[SupportedChainId.ZOO] = "0xAe2cf1E403aAFE6C05A5b8Ef63EB19ba591d8511";

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_ROUTER_ADDRESS = '0x5615CDAb10dc425a742d643d949a7F474C01abc4'
const LUX_V3_FACTORY_ADDRESS = "0x80bBc7C4C7a59C899D1B37BC14539A22D5830a84";
const LUX_TESTNET_V3_FACTORY_ADDRESS = "0x80bBc7C4C7a59C899D1B37BC14539A22D5830a84";
const ZOO_FACTORY_ADDRESS = "0x80bBc7C4C7a59C899D1B37BC14539A22D5830a84";
const LUX_ROUTER_ADDRESS = '0x939bC0Bca6F9B9c52E6e3AD8A3C590b5d9B9D10E';
const LUX_TESTNET_ROUTER_ADDRESS = '0x939bC0Bca6F9B9c52E6e3AD8A3C590b5d9B9D10E';
const ZOO_ROUTER_ADDRESS = '0x939bC0Bca6F9B9c52E6e3AD8A3C590b5d9B9D10E';
const CELO_V3_MIGRATOR_ADDRESSES = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const CELO_QUOTER_ADDRESSES = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESSES = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'
const BASE_V3_CORE_FACTORY_ADDRESSES = '0x33128a8fC17869897dcE68Ed026d694621f6FDfD'
const BASE_ROUTER_ADDRESS = '0x2626664c2603336E57B271c5C0b26F421741e481'
const BASE_V3_MIGRATOR_ADDRESSES = '0x23cF10b1ee3AdfCA73B0eF17C07F7577e7ACd2d7'
const BASE_MULTICALL_ADDRESS = '0x091e99cb1C49331a94dD62755D168E941AbD0693'
const BASE_QUOTER_ADDRESSES = '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a'
const BASE_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1'
const BASE_TICK_LENS_ADDRESSES = '0x0CdeE061c75D43c82520eD998C23ac2991c9ac6d'

const BNB_V3_CORE_FACTORY_ADDRESSES = '0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7'
const BNB_ROUTER_ADDRESS = '0xB971eF87ede563556b2ED4b1C0b0019111Dd85d2'
const BNB_V3_MIGRATOR_ADDRESSES = '0x32681814957e0C13117ddc0c2aba232b5c9e760f'
const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const BNB_QUOTER_ADDRESSES = '0x78D78E420Da98ad378D7799bE8f4AF69033EB077'
const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613'
const BNB_TICK_LENS_ADDRESSES = '0xD9270014D396281579760619CCf4c3af0501A47C'

const AVAX_V3_CORE_FACTORY_ADDRESSES = '0x740b1c1de25031C31FF4fC9A62f554A55cdC1baD'
const AVAX_ROUTER_ADDRESS = '0xbb00FF08d01D300023C629E8fFfFcb65A5a578cE'
const AVAX_V3_MIGRATOR_ADDRESSES = '0x44f5f1f5E452ea8d29C890E8F6e893fC0f1f0f97'
const AVAX_MULTICALL_ADDRESS = '0x0139141Cd4Ee88dF3Cdb65881D411bAE271Ef0C2'
const AVAX_QUOTER_ADDRESSES = '0xbe0F5544EC67e9B3b2D979aaA43f18Fd87E6257F'
const AVAX_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x655C406EBFa14EE2006250925e54ec43AD184f8B'
const AVAX_TICK_LENS_ADDRESSES = '0xEB9fFC8bf81b4fFd11fb6A63a6B0f098c6e21950'

const ZORA_V3_CORE_FACTORY_ADDRESSES = '0x7145F8aeef1f6510E92164038E1B6F8cB2c42Cbb'
const ZORA_ROUTER_ADDRESS = '0x7De04c96BE5159c3b5CeffC82aa176dc81281557'
const ZORA_V3_MIGRATOR_ADDRESSES = '0x048352d8dCF13686982C799da63fA6426a9D0b60'
const ZORA_MULTICALL_ADDRESS = '0xA51c76bEE6746cB487a7e9312E43e2b8f4A37C15'
const ZORA_QUOTER_ADDRESSES = '0x11867e1b3348F3ce4FcC170BC5af3d23E07E64Df'
const ZORA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xbC91e8DfA3fF18De43853372A3d7dfe585137D78'
const ZORA_TICK_LENS_ADDRESSES = '0x209AAda09D74Ad3B8D0E92910Eaf85D2357e3044'

const BLAST_V3_CORE_FACTORY_ADDRESSES = '0x792edAdE80af5fC680d96a2eD80A44247D2Cf6Fd'
const BLAST_ROUTER_ADDRESS = '0x549FEB8c9bd4c12Ad2AB27022dA12492aC452B66'
const BLAST_V3_MIGRATOR_ADDRESSES = '0x15CA7043CD84C5D21Ae76Ba0A1A967d42c40ecE0'
const BLAST_MULTICALL_ADDRESS = '0xdC7f370de7631cE9e2c2e1DCDA6B3B5744Cf4705'
const BLAST_QUOTER_ADDRESSES = '0x6Cdcd65e03c1CEc3730AeeCd45bc140D57A25C77'
const BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xB218e4f7cF0533d4696fDfC419A0023D33345F28'
const BLAST_TICK_LENS_ADDRESSES = '0x2E95185bCdD928a3e984B7e2D6560Ab1b17d7274'

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BLAST]: BLAST_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ZORA]: ZORA_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.AVALANCHE]: AVAX_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BASE]: BASE_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.LUX]: LUX_V3_FACTORY_ADDRESS,
  [SupportedChainId.LUX_TESTNET]: LUX_TESTNET_V3_FACTORY_ADDRESS,
  [SupportedChainId.ZOO]: ZOO_FACTORY_ADDRESS,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BLAST]: BLAST_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ZORA]: ZORA_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.AVALANCHE]: AVAX_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BASE]: BASE_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.BLAST]: BLAST_MULTICALL_ADDRESS,
  [SupportedChainId.ZORA]: ZORA_MULTICALL_ADDRESS,
  [SupportedChainId.AVALANCHE]: AVAX_MULTICALL_ADDRESS,
  [SupportedChainId.BNB]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.BASE]: BASE_MULTICALL_ADDRESS,
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.LUX]: "0xd25F88CBdAe3c2CCA3Bb75FC4E723b44C0Ea362F",
  [SupportedChainId.LUX_TESTNET]: "0xd25F88CBdAe3c2CCA3Bb75FC4E723b44C0Ea362F",
  [SupportedChainId.ZOO]: "0xd25F88CBdAe3c2CCA3Bb75FC4E723b44C0Ea362F",
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
  [SupportedChainId.LUX]: LUX_ROUTER_ADDRESS,
  [SupportedChainId.LUX_TESTNET]: LUX_TESTNET_ROUTER_ADDRESS,
  [SupportedChainId.ZOO]: ZOO_ROUTER_ADDRESS,
  [SupportedChainId.BLAST]: BLAST_ROUTER_ADDRESS,
  [SupportedChainId.AVALANCHE]: AVAX_ROUTER_ADDRESS,
  [SupportedChainId.ZORA]: ZORA_ROUTER_ADDRESS,
  [SupportedChainId.BNB]: BNB_ROUTER_ADDRESS,
  [SupportedChainId.BASE]: BASE_ROUTER_ADDRESS,
  [SupportedChainId.CELO]: CELO_ROUTER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_ROUTER_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BLAST]: BLAST_QUOTER_ADDRESSES,
  [SupportedChainId.ZORA]: ZORA_QUOTER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_QUOTER_ADDRESSES,
  [SupportedChainId.AVALANCHE]: AVAX_QUOTER_ADDRESSES,
  [SupportedChainId.BASE]: BASE_QUOTER_ADDRESSES,
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.LUX]: "0x12e2B76FaF4dDA5a173a4532916bb6Bfa3645275",
  [SupportedChainId.LUX_TESTNET]: "0x12e2B76FaF4dDA5a173a4532916bb6Bfa3645275",
  [SupportedChainId.ZOO]: "0x12e2B76FaF4dDA5a173a4532916bb6Bfa3645275",
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.BLAST]: BLAST_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ZORA]: ZORA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.AVALANCHE]: AVAX_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BASE]: BASE_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.LUX]: "0x7a4C48B9dae0b7c396569b34042fcA604150Ee28",
  [SupportedChainId.LUX_TESTNET]: "0x7a4C48B9dae0b7c396569b34042fcA604150Ee28",
  [SupportedChainId.ZOO]: "0x7a4C48B9dae0b7c396569b34042fcA604150Ee28",
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.BLAST]: BLAST_TICK_LENS_ADDRESSES,
  [SupportedChainId.AVALANCHE]: AVAX_TICK_LENS_ADDRESSES,
  [SupportedChainId.BNB]: BNB_TICK_LENS_ADDRESSES,
  [SupportedChainId.ZORA]: ZORA_TICK_LENS_ADDRESSES,
  [SupportedChainId.BASE]: BASE_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.LUX]: "0x57A22965AdA0e52D785A9Aa155beF423D573b879",
  [SupportedChainId.LUX_TESTNET]: "0x57A22965AdA0e52D785A9Aa155beF423D573b879",
  [SupportedChainId.ZOO]: "0x57A22965AdA0e52D785A9Aa155beF423D573b879",
}
